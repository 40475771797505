/*
TODO: note that since adding multiple days editing hearing councils is not supported is discussed
      so if editing should be supported in the future followings must be considered:
      - mutoonIds are being set on add as {value: string; label: string} and are being sent as ids
        which should be handled on GET then, in order to view mutoon options correctly
        */

// LINK .\:100

import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  HearingCouncil,
  OneHearingCouncilQuery,
  useAllAdminsQuery,
  useAllCertificatesQuery,
  useCreateHearingCouncilMutation,
  useMutoonCategoriesQuery,
  useOneHearingCouncilQuery,
  useUpdateHearingCouncilFormMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { AR_CONTENT_LANG, EN_CONTENT_LANG, useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import {
  AddHearingCouncilMutationVariablesType,
  HearingCouncilDto,
} from 'src/types/hearing-council';
import { minutesSinceMidnight } from 'src/utils/minutes-calc-func';

const useAddNewHearingCouncil = () => {
  // #region States
  const confirm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const { hearingCouncilSchema } = useSchemas();
  const router = useNavigate();
  const { hearingCouncilId } = useParams();
  const { t, currentLang } = useLocales();
  const requiredLangs = [AR_CONTENT_LANG(t), EN_CONTENT_LANG(t)];
  const [currentTabValue, setCurrentTabValue] = useState(requiredLangs[0].value);
  const { state }: { state: OneHearingCouncilQuery['hearing_councils_by_pk'] | undefined } =
    useLocation();
  const isEditing = !!state;
  const [dataToSend, setDataToSend] = useState<AddHearingCouncilMutationVariablesType>();
  // #endregion States

  let defaultValues: AddHearingCouncilMutationVariablesType = {
    sessionName: state ? state?.sessionName : {},
    sheikhName: state ? state?.sheikhName! : '',
    description: state ? state.description : {},
    startDate: state ? state?.startDate : new Date(),
    expiryDate: state ? state?.expiryDate : moment().add(2, 'hours').toDate(),
    price: state ? state?.price! : 50,
    appointmentLimit: state ? state?.appointmentLimit : 10,
    days: state?.days || ['Sun'],
    duration: state?.duration || 1,
    autoCertificate: false,
    certificateIds: [],
    mutoon: [],
    mutoonIds: [],
    generalMutoonIds: [],
  };

  // #region form
  for (let i = 0; i < requiredLangs.length && !state; i++) {
    defaultValues.description[requiredLangs[i].value] = '';
    defaultValues.sessionName[requiredLangs[i].value] = '';
  }

  const methods = useForm<AddHearingCouncilMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(hearingCouncilSchema(requiredLangs)),
  });
  console.log(methods.formState.errors);

  const isTheOwnerTheCreator =
    JSON.parse(localStorage.getItem('user')!)!?.id === methods.watch().sheikhName;

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddHearingCouncilMutationVariablesType) => {
      const newData = { ...data };
      newData.startDate = moment(newData.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      newData.expiryDate = moment(newData.expiryDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');

      // Mutoon and generalMutoons are not sent to the server, they are just for viewing purposes
      delete newData.mutoon;
      delete newData.generalMutoon;

      // Unify mutoonIds and generalMutoonIds in one array as they are separated for viewing purposes also
      newData.mutoonIds = [...newData.mutoonIds!, ...newData.generalMutoonIds!];

      // Delete generalMutoonIds
      delete newData.generalMutoonIds;

      // Convert to minutes
      newData.startTime = minutesSinceMidnight(moment(newData.startDate).toDate());
      newData.endTime = minutesSinceMidnight(moment(newData.expiryDate).toDate());

      if (isTheOwnerTheCreator) delete (newData as any).sheikhName;
      delete (newData as any).mutoonMatnName;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors, requiredLangs);
  // #endregion form

  // #region Services
  // Create hearing council
  const { mutate: createHearingCouncil, isLoading: isAdding } = useCreateHearingCouncilMutation({
    onSuccess: () => {
      confirm.onFalse();
      enqueueSnackbar({
        variant: 'success',
        message: 'Hearing council has been created successfully',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      router(paths.dashboard.contentManagement.hearingCouncils);
    },
  });

  // Update hearing council
  const { mutate: update, isLoading: isUpdating } = useUpdateHearingCouncilFormMutation({
    onSuccess: () => router(paths.dashboard.contentManagement.hearingCouncils),
  });

  const mutate = isEditing
    ? () =>
        update({
          hearingCouncilId,
          appointmentLimit: dataToSend?.appointmentLimit,
          description: dataToSend?.description,
          expiryDate: dataToSend?.expiryDate,
          sessionName: dataToSend?.sessionName,
          sheikhName: dataToSend?.sheikhName,
          startDate: dataToSend?.startDate,
        })
    : () =>
        createHearingCouncil({
          ...dataToSend!,
          mutoonMatnId: dataToSend?.mutoonIds!?.[0]!,
          endTime: dataToSend?.endTime!,
          startTime: dataToSend?.startTime!,
        });

  // Get mutoon (not general)
  const { data: mutoonOptions, isSuccess: hasGotMtns } = useMutoonCategoriesQuery({
    categoryCond: {},
    isGeneral: { _eq: false },
  });

  // Get general mutoon
  const { data: generalMutoonOptions, isSuccess: hasGotGeneralMatns } = useMutoonCategoriesQuery({
    categoryCond: {},
    isGeneral: { _eq: true },
  });

  // Get current hearing council data
  const {} = useOneHearingCouncilQuery(
    {
      hearingCouncilId: hearingCouncilId!,
    },
    {
      enabled: !!hearingCouncilId,
      onSuccess: (data) => {
        methods.reset({ ...data.hearing_councils_by_pk! } as any);
        !data.hearing_councils_by_pk?.sheikhName &&
          methods.setValue('sheikhName', JSON.parse(localStorage.getItem('user')!)!?.id);
      },
    }
  );

  // Get admins
  // Note that: sheikh will be chosen from admins list
  const { data: admins } = useAllAdminsQuery();

  // Get certificates
  const { data: certificates, isLoading: isLoadingCertificates } = useAllCertificatesQuery();
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `sessionName.${currentTabValue}`,
      methods.watch().sessionName[currentTabValue] || ''
    );
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);
  // #endregion useEffect

  return {
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    handleChangeTab,
    requiredLangs,
    langsWithErrors,
    t,
    currentLang,
    hasGotMtns,
    hasGotGeneralMatns,
    mutoonOptions,
    generalMutoonOptions,
    isEditing,
    isAdding,
    dataToSend,
    confirm,
    mutate,
    admins,
    certificates,
    isLoadingCertificates,
    isUpdating,
  };
};

export default useAddNewHearingCouncil;
