import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import {
  Order_By,
  useAddMatnMutation,
  useAllReferenceBooksQuery,
  useAttachMatnMutation,
  useUpdateMatnMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { AR_CONTENT_LANG, EN_CONTENT_LANG, useLocales } from 'src/locales';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddGeneralMatnMutationVariables, NewMatnDialogPropsType } from 'src/types/mutoon';
import { findParentIdString } from 'src/utils/mutoon-categories/find-last-id';

const useAddNewGeneralMatnDialog = ({ onClose, matn }: NewMatnDialogPropsType) => {
  // #region States
  const addBookDialog = useBoolean();
  const { t, currentLang } = useLocales();
  const { categoryId, '*': splat } = useParams();

  const queryClient = useQueryClient();
  const confirm = useBoolean();
  const requiredLangs = [AR_CONTENT_LANG(t), EN_CONTENT_LANG(t)];
  const [dataToSend, setDataToSend] = useState<AddGeneralMatnMutationVariables>();
  const [currentTabValue, setCurrentTabValue] = useState(requiredLangs[0].value);
  const { generalMatnSchema } = useSchemas();
  const isEditing = !!matn;
  const categoryIdIdentifier = categoryId || findParentIdString(splat)!;

  const checkedMatn =
    matn?.__typename === 'mutoons'
      ? matn
      : matn?.__typename === 'mutoon_categories'
      ? matn.mutoon
      : undefined;
  // #endregion States

  let defaultValues: AddGeneralMatnMutationVariables = {
    description: checkedMatn?.description || {},
    name: checkedMatn?.name || {},
    referenceBookBookId: checkedMatn?.referenceBookBookId || undefined!,
    matnId: checkedMatn?.id || '',
    isGeneral: true,
  };

  // #region form
  for (let i = 0; i < requiredLangs.length && !matn; i++) {
    defaultValues.description && (defaultValues.description[requiredLangs[i].value] = '');
    defaultValues.name[requiredLangs[i].value] = '';
  }

  const methods = useForm<AddGeneralMatnMutationVariables>({
    defaultValues,
    resolver: yupResolver<AddGeneralMatnMutationVariables>(generalMatnSchema(requiredLangs)),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddGeneralMatnMutationVariables) => {
      delete data.matnId;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = Object.values(errors)
    .map((errors) => Object.keys(errors))
    .flat()
    .filter((value, index, array) => array.indexOf(value) !== index);
  // #endregion form

  // #region Services
  const { data: books, isLoading } = useAllReferenceBooksQuery({
    order_by: { bookName: Order_By.Asc },
  });

  const { mutate: addMatn, isLoading: isAdding } = useAddMatnMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] });
      confirm.onFalse();
      onClose();
      categoryIdIdentifier &&
        attachMatn({
          categoryId: categoryIdIdentifier,
          mutoonId: data.insert_mutoons_one?.id,
        });
    },
  });

  const { mutate: editMatn, isLoading: isUpdating } = useUpdateMatnMutation({
    onSuccess: () => {
      onClose();
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] });
    },
  });

  const { mutate: attachMatn, isLoading: isAttaching } = useAttachMatnMutation({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] });
    },
  });

  const mutate = isEditing
    ? () =>
        editMatn({
          ...dataToSend,
          matnId: checkedMatn?.id,
        })
    : () =>
        addMatn({
          ...dataToSend,
          // categoryId: categoryIdIdentifier,
        });
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers
  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description!?.[currentTabValue] || ''
    );
    methods.setValue(`name.${currentTabValue}`, methods.watch().name[currentTabValue] || '');
  }, [methods, currentTabValue]);
  // #endregion useEffect

  return {
    addBookDialog,
    confirm,
    currentTabValue,
    handleSubmit,
    langsWithErrors,
    methods,
    onSubmit,
    books,
    isLoading,
    isAdding,
    t,
    currentLang,
    isUpdating,
    handleChangeTab,
    allLangs: requiredLangs,
    isEditing,
    mutate,
  };
};

export default useAddNewGeneralMatnDialog;
