import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { useLocales } from 'src/locales';
import { Locale } from 'src/schemas/hooks/useSchemas';

const useLangsWithErrors = (errors: FieldErrors, requiredLangs?: Locale[]) => {
  const { allLangs } = useLocales();

  const langsWithErrors = [
    // @ts-ignore
    ...new Set(
      Object.values(errors)
        .map((errors) => Object.keys(errors!))
        .flat()
        .filter((error) => (requiredLangs || allLangs).map((lang) => lang.value).includes(error))
    ),
  ];
  console.log('langsWithErrors', langsWithErrors);
  return langsWithErrors;
};

export default useLangsWithErrors;
