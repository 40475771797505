import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
// components
import { enUS, esES, arSA } from '@mui/material/locale';
import { useSettingsContext } from 'src/components/settings';
import { AR_CONTENT_LANG } from '.';
// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const langStorage = localStorageGetItem('i18nextLng') || 'en';

  // Note that you should translate content langs label when used in other places
  const allContentLangs = useMemo(() => [AR_CONTENT_LANG(t)], [langStorage]);

  const allViewLangs = useMemo(
    () => [
      {
        label: t('translationTypes.english'),
        value: 'en',
        systemValue: enUS,
        icon: 'flagpack:gb-nir',
        name: 'english',
      },
      // {
      //   label: t('translationTypes.spanish'),
      //   value: 'es',
      //   systemValue: esES,
      //   icon: 'flagpack:es',
      //   name: 'spanish',
      // },
      {
        label: t('translationTypes.arabic'),
        value: 'ar',
        systemValue: arSA,
        icon: 'flagpack:sa',
        name: 'arabic',
      },
    ],
    [langStorage]
  );

  const settings = useSettingsContext();

  const currentContentLang = allContentLangs.find((lang) => lang.value === langStorage) || {
    label: t('translationTypes.arabic'),
    value: 'ar',
    systemValue: arSA,
    icon: 'flagpack:sa',
    name: 'arabic',
  };

  const currentViewLang = allViewLangs.find((lang) => lang.value === langStorage) || {
    label: t('translationTypes.english'),
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
    name: 'english',
  };

  const onChangeLang = useCallback(
    (newlang: string) => {
      i18n.changeLanguage(newlang);
      settings.onChangeDirectionByLang(newlang);
    },
    [i18n, settings]
  );

  return {
    allLangs: allContentLangs,
    t,
    currentLang: currentContentLang,
    onChangeLang,
    allViewLangs,
    currentViewLang,
  };
}
