import moment from 'moment';

export const minutesSinceMidnight = (date: Date) => {
  const givenMoment = moment(date).utc(); // Create moment object from the given date

  // Get the number of minutes since midnight (hours * 60 + minutes)
  const minutes = givenMoment.hours() * 60 + givenMoment.minutes();

  return minutes;
};

export const minutesToDateSinceMidnight = (minutes: number) => {
  const today = moment().startOf('day').utc(); // Get the start of the current day (midnight)

  const resultMoment = today.add(minutes, 'minutes'); // Add the minutes since midnight

  return resultMoment.toDate(); // Convert moment object to Date object
};
