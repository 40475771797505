import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  alpha,
} from '@mui/material';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { NewMatnDialogPropsType } from 'src/types/mutoon';
import useAddNewGeneralMatnDialog from './use-add-new-general-matn-dialog';
import { AR_CONTENT_LANG, EN_CONTENT_LANG } from 'src/locales';

const AddNewGeneralMatnDialog = ({ open, onClose, matn }: NewMatnDialogPropsType) => {
  const {
    addBookDialog,
    confirm,
    currentTabValue,
    handleSubmit,
    langsWithErrors,
    methods,
    onSubmit,
    books,
    isLoading,
    isAdding,
    t,
    currentLang,
    isUpdating,
    handleChangeTab,
    allLangs,
    isEditing,
    mutate,
  } = useAddNewGeneralMatnDialog({ onClose, open, matn });

  if (!open) return <></>;
  return (
    <>
      <Dialog
        open={open && !confirm.value && !addBookDialog.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.addNewMatn.header')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <AppLoadingAndErrorWrapper
              isLoading={isLoading}
              errorMessage={null}
              sx={{ height: 'auto' }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Stack py={1} spacing={2}>
                      <Block label={t('dialogs.addNewMatn.PDF')}>
                        <RHFSelect name="referenceBookBookId">
                          {books?.reference_books?.map((book) => (
                            <MenuItem key={book.bookId} value={book.bookId}>
                              {book.bookName!?.[currentLang.value]}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      </Block>
                      <Tabs
                        value={currentTabValue}
                        onChange={handleChangeTab}
                        sx={{
                          px: 3,
                          boxShadow: (theme) =>
                            `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                        }}
                      >
                        {[AR_CONTENT_LANG(t), EN_CONTENT_LANG(t)]!?.map((tab) => (
                          <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                            sx={{
                              color: langsWithErrors.includes(tab.value)
                                ? 'red !important'
                                : 'text.primary',
                            }}
                          />
                        ))}
                      </Tabs>
                    </Stack>
                    <Stack spacing={1}>
                      <Block label={t('dialogs.addNewMatn.name')}>
                        <RHFTextField name={`name.${currentTabValue}`} />
                      </Block>
                      <Block label={t('dialogs.addNewMatn.description')}>
                        <RHFTextField name={`description.${currentTabValue}`} />
                      </Block>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppLoadingButton
                      label={isEditing ? t('buttons.edit') : t('buttons.add')}
                      isLoading={false}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppButton
                      onClick={onClose}
                      label={t('buttons.cancel')}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewGeneralMatnDialog;
