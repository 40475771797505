import { arSA, enUS } from '@mui/material/locale';
import { TFunction } from 'i18next';

export { default as useLocales } from './use-locales';

export const AR_CONTENT_LANG = (t: TFunction<'ns1', undefined>) => ({
  label: t('translationTypes.arabic'),
  value: 'ar',
  systemValue: arSA,
  icon: 'flagpack:sa',
  name: 'arabic',
});

export const EN_CONTENT_LANG = (t: TFunction<'ns1', undefined>) => ({
  label: t('translationTypes.english'),
  value: 'en',
  systemValue: enUS,
  icon: 'flagpack:gb-nir',
  name: 'english',
});

export const ALL_CONTENT_LANGS = [
  EN_CONTENT_LANG,
  AR_CONTENT_LANG,
  // {
  //   label: t('translationTypes.spanish'),
  //   value: 'es',
  //   systemValue: esES,
  //   icon: 'flagpack:es',
  //   name: 'spanish',
  // },
];
