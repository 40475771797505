const englishJson = {
  breadcrumbs: {
    mutoon: 'Mutoon',
    categories: 'Categories',
    levels: 'Levels',
    books: 'Books',
  },
  snackbar: {
    keyCopiedToClipboard: 'Key copied to clipboard',
    completedSuccessfully: 'Completed successfully',
  },
  constants: {
    dateOperators: {
      greaterThan: 'After',
      lessThan: 'Before',
    },
    all: 'All',
  },
  translationTypes: {
    english: 'English',
    arabic: 'Arabic',
    spanish: 'Spanish',
    isRequired: 'is required',
  },
  buttons: {
    reply: 'Reply',
    cancel: 'Cancel',
    add: 'Add',
    edit: 'Edit',
    subscribe: 'Subscribe',
    editMatnSectionAndAddNewWordsAndSounds: 'Edit and add new words and sounds',
    approve: 'Approve',
    reject: 'Reject',
    showAnalysis: 'Show analysis',
    reset: 'Reset',
    submit: 'Submit',
    download: 'Download',
    startSharing: 'Start sharing',
  },
  days: {
    sun: 'Sunday',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
  },
  frequency: {
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
  },
  examsStatus: {
    active: 'Active',
    inactive: 'Inactive',
  },
  userRecords: {
    rejected: 'Rejected',
    approved: 'Approved',
    pending: 'Pending',
    all: 'All',
  },
  questionsChoicesType: {
    count_errors: 'Count errors',
    is_there_error: 'Is there errors',
    only_choose: 'Choose',
    where_the_error: 'Where is the error',
  },
  publicWords: {
    yes: 'Yes',
    no: 'No',
  },
  filterDrawer: {
    filters: 'Filters',
    resetButton: 'Reset filters',
  },
  tableLabels: {
    edit: 'Edit',
    editQuestions: 'Edit questions',
    delete: 'Delete',
    preview: 'Preview',
    approve: 'Approve',
    reject: 'Reject',
  },
  sidebar: {
    statistics: 'Statistics',
    contactUs: 'Contact Us',
    certificates: {
      builder: 'Certificates editor',
      table: 'Certificates table',
      root: 'Certificates',
    },
    faqs: 'FAQs',
    sessions: {
      todaySessions: 'Today sessions',
      upcomingSessions: 'Upcoming sessions',
      root: 'Sessions',
    },
    mutoon: {
      categories: 'Categories',
      exams: 'Exams',
      root: 'Mutoon',
      books: 'Books',
      questionsBank: 'Questions bank',
    },
    appUsers: 'App users',
    knowledgeBases: 'Knowledge bases',
    badges: 'Badges',
    voiceRequests: 'Voice requests',
    scientificSessions: 'Scientific sessions',
    hearingCouncils: 'Hearing councils',
    discussionRoom: 'Discussion room',
    rolesAndUsers: 'Roles and users',
    roles: 'Roles',
    users: 'Users',
    contentManagement: 'Content management',
    helpCenter: 'Help center',
    userAuthorization: {
      title: 'User authorization',
      teachersRequests: 'Teachers requests',
      schoolRequests: 'School requests',
      usersCategories: 'User categories',
      school: 'School',
    },
  },
  titles: {
    login: 'Login',
    logout: 'Logout',
    mutoon: 'Mutoon',
    notFound: 'Page not found',
    register: 'Register',
    addRole: 'Add Role',
    mutoonAdminDashboard: 'Mutoon admin dashboard',
    addBadge: 'Add Badge',
    addBook: 'Add Book',
    addExam: 'Add exam',
    addFaq: 'Add FAQ',
    addHearingCouncil: 'Add hearing council',
    addKnowledgeBase: 'Add knowledge base',
    addSession: 'Add session',
    addQuestion: 'Add question',
  },
  tables: {
    exportData: 'Export data',
    search: 'Search',
    rowsPerPageLabel: 'Rows per page',
    sort: {
      sortDirection: 'Sort direction',
      ascending: 'Ascending',
      descending: 'Descending',
      sortBy: 'Sort by',
    },
    categories: {
      addNew: 'Add new category',
      type: 'Category',
      headLabels: {
        name: 'Name',
        type: 'Type',
        actions: 'Actions',
        date: 'Date',
      },
      filters: {
        onlyMutoon: 'Only mutoon',
      },
    },
    faqs: {
      addNew: 'Add FAQ',
      headLabels: {
        actions: 'Actions',
        answer: 'Answer',
        createdAt: 'Created At',
        question: 'Question',
      },
    },
    badges: {
      headLabels: {
        badgeName: 'Badge Name',
        count: 'Count',
        badgeImage: 'Badge Image',
        lastUpdated: 'Last Updated',
        createdAt: 'Created At',
        actions: 'Actions',
        badgeType: 'Badge Type',
      },
    },
    levels: {
      addNew: 'Add new level',
      headLabels: {
        nameOfLevel: 'Name of level',
        date: 'Date',
        actions: 'Actions',
      },
    },
    mutoon: {
      addNew: 'Add new matn',
      addNewGeneral: 'Add new general matn',
      headLabels: {
        nameOfMatn: 'Name of matn',
        description: 'Description',
        numberOfHadith: 'Number of hadith',
        date: 'Date',
        actions: 'Actions',
        isGeneral: 'Is general',
      },
    },
    books: {
      addNew: 'Add new book',
      headLabels: {
        bookAuthor: 'Book author',
        bookName: 'Book name',
        actions: 'Actions',
        date: 'Date',
      },
    },
    matnSections: {
      addNew: 'Add new section',
      difficultWords: 'Difficult words',
      sounds: 'Sounds',
      headLabels: {
        name: 'Section name',
        description: 'Description',
        actions: 'Actions',
        date: 'Date',
      },
    },
    matnSectionsDiffWords: {
      headLabels: {
        word: 'Word',
        actions: 'Actions',
      },
    },
    matnSectionSounds: {
      headLabels: {
        name: 'Name',
        description: 'Description',
        preview: 'Preview',
        actions: 'Actions',
      },
    },
    voiceRequests: {
      headLabels: {
        userName: 'User Name',
        userEmail: 'User Email',
        sendingDate: 'Sending Date',
        nameOfMatn: 'Matn',
        preview: 'Preview',
        status: 'Status',
        actions: 'Actions',
      },
    },
    dashboardUsers: {
      headLabels: {
        actions: 'Actions',
        name: 'Name',
        role: 'Role',
        email: 'Email',
      },
      addNew: 'Add New User',
    },
    exams: {
      headLabels: {
        title: 'Title',
        description: 'Description',
        matn: 'Matn',
        actions: 'Actions',
      },
      addNew: 'Add New Exam',
    },
    questions: {
      headLabels: {
        matn: 'Matn',
        matnSection: 'Matn section',
        question: 'Question',
        questionChoices: 'Question choices',
        questionType: 'Question type',
      },
      addNew: 'Add New Questions',
      filters: {
        choicesType: 'Choices type',
        isQuiz: 'Belongs to',
        matn: 'Matn',
        enums: {
          isQuiz: {
            exam: 'Exam',
            quiz: 'Quiz',
          },
        },
      },
    },
    knowledgeBases: {
      headLabels: {
        content: 'Content',
        title: 'Title',
        coverUrl: 'Cover',
        createdAt: 'Created at',
        actions: 'Actions',
      },
    },
    certificates: {
      headLabels: {
        title: 'Certificates',
        createdAt: 'Created at',
        image: 'Image',
        actions: 'Actions',
      },
    },
    categoryMutoon: {
      type: 'Matn',
    },
    finishedHearingCouncilsAnalysis: {
      headLabels: {
        attendanceRate: 'Attendance Rate',
        email: 'Email',
        attendanceDuration: 'Attendance duration',
        name: 'Name',
        registeredIn: 'Registered In',
        joinType: 'Join Type',
      },
      online: 'Online',
      onsite: 'Onsite',
      createCertResWarning: 'No certificates have been created',
      alertMessage: 'Please choose a certificate first',
      tabs: {
        schools: 'Schools',
        users: 'Users',
      },
    },
    contactUs: {
      headLabels: {
        status: 'Status',
        actions: 'Actions',
        date: 'Date',
        email: 'Email',
        name: 'Name',
        problem: 'Problem',
      },
      status: {
        active: 'Pending',
        inactive: 'Solved',
        replied: 'Replied',
      },
      filters: {
        status: 'Status',
        createdAt: 'Created At',
      },
    },
    roles: {
      headLabels: {
        actions: 'Actions',
        roleName: 'Role name',
        users: 'Users',
      },
    },
    users: {
      headLabels: {
        firstName: 'First Name',
        lastName: 'Last Name',
        createdAt: 'Created at',
        numberOfSessions: 'Number of sessions',
        numberOfHearingCouncils: 'Number of hearing councils',
        actions: 'Actions',
      },
    },
    teachersRequests: {
      headLabels: {
        actions: 'Actions',
        createdAt: 'Requesting date',
        fieldOfStudy: 'Field of study',
        filesCount: 'Files Count',
        firstName: 'First Name',
        lastName: 'Last Name',
        status: 'Status',
      },
    },
    schoolRequests: {
      headLabels: {
        actions: 'Actions',
        createdAt: 'Requesting date',
        status: 'Status',
        schoolName: 'School Name',
        city: 'City',
        schoolAddress: 'School Address',
        filesCount: 'Files Count',
        country: 'Country',
      },
    },
    students: {
      headLabels: {
        firstName: 'First Name',
        firstNameEn: 'English first name',
        lastName: 'Last Name',
        lastNameEn: 'English Last Name',
        email: 'Email',
        birthDate: 'Birth Date',
        actions: 'Actions',
      },
    },
  },
  dialogs: {
    replyToMsg: {
      header: 'Reply to contact us messages',
      confirmMessage: 'Are you sure you want to send this message?',
    },
    addNewRole: {
      roleName: 'Role name',
      roleInfo: 'Role info',
      permissions: 'Permissions',
      enterRoleName: 'Enter role name',
      actionsTable: {
        create: 'Create',
        delete: 'Delete',
        edit: 'Edit',
        systemCriteria: 'System criteria',
        view: 'View',
        rows: {
          Admins: 'Admins',
          Badges: 'Badges',
          Categories: 'Categories',
          Certificates: 'Certificates',
          'Contact Us': 'Contact us',
          Content: 'Content',
          'Content Pages': 'Content pages',
          'Content Recommendations': 'Content recommendations',
          Dashboard: 'Dashboard',
          'Discussion Rooms': 'Discussion rooms',
          Donations: 'Donations',
          Exams: 'Exams',
          'Hearing Council': 'Hearing council',
          'Hearing Councils': 'Hearing councils',
          'Knowledge Bases': 'Knowledge bases',
          Notifications: 'Notifications',
          'Payment Methods': 'Payment Methods',
          'Ratings and Reviews': 'Rating and reviews',
          'Reference Books': 'Reference books',
          Roles: 'Roles',
          Session: 'Session',
          Sessions: 'Sessions',
          Sounds: 'Sounds',
          Statistics: 'Statistics',
          User: 'User',
          'User Credit Card Infos': 'User credit card info',
          'User Record': 'User record',
        },
      },
    },
    accountPopover: {
      home: 'Home',
      logout: 'Logout',
      profile: 'Profile',
      settings: 'Settings',
    },
    settingsDrawer: {
      contrast: 'Contrast',
      direction: 'Direction',
      layout: 'Layout',
      mode: 'Mode',
      presets: 'Presets',
      settings: 'Settings',
      stretch: 'Stretch',
      fullscreen: 'Full screen',
    },
    addNewKnowledgeBase: {
      uploadImage: 'Upload image',
    },
    rejectRecord: {
      header: 'Reject Record',
      adminMessage: 'Admin Message',
    },
    addNewCategory: {
      name: 'Name',
      description: 'Description',
      header: 'Add new category',
    },
    addNewLevel: {
      name: 'Name',
      description: 'Description',
      header: 'Add new level',
    },
    addNewMatn: {
      name: 'Name',
      description: 'Description',
      PDF: 'PDF',
      addNewBook: 'Add new book',
      numberOfHadith: 'Number of hadith',
      header: 'Add new matn',
    },
    addNewHearingCouncil: {
      sessionName: 'Session Name',
      sheikhName: 'Manager name',
      description: 'Description',
      startDate: 'Start Date',
      expiryDate: 'Expiry Date',
      category: 'Category',
      level: 'Level',
      matn: 'Matn',
      generalMatn: 'General Matn',
      price: 'Price',
      appointmentLimit: 'Appointment Limit (minutes)',
      accordions: {
        councilDetails: 'Council Details',
      },
      autoCertificate: 'Auto Certificate',
      certificate: 'Certificate',
    },
    addNewBook: {
      title: 'Title',
      bookName: 'Book name',
      bookAuthor: 'Book author',
      bookLanguage: 'Book language',
      fromSchool: 'From school',
      nazemName: 'Nazem name',
      dateOfBorn: 'Date of born',
      dateOfDeath: 'Date of death',
      numberOfLines: 'Number of lines',
      uploadPdf: 'Upload PDF',
      header: 'Add new book',
    },
    addNewSession: {
      description: 'Description',
      title: 'Title',
      startDate: 'Start Date',
      expiryDate: 'Expiry Date',
      category: 'Category',
      level: 'Level',
      matn: 'Matn',
      enableConversation: 'Enable Conversation',
      enableRaiseHand: 'Enable Raise Hand',
      enableRecording: 'Enable Recording',
      enableUploadFiles: 'Enable upload files',
      enableVideo: 'Enable video',
      frequency: 'Frequency',
      days: 'Days',
      maxUserCount: 'Max User Count',
      prerequisite: 'Prerequisites',
      duration: 'Duration (minutes)',
      prerequisites: 'Prerequisites',
      accordions: {
        dateAndTime: 'Date and Time',
        sessionPreferences: 'Session Preferences',
        matnDetails: 'Matn Details',
        certificateDetails: 'Certificate Details',
      },
    },
    addNewMatnSection: {
      name: 'Name',
      description: 'Description',
      matnSectionDetails: 'Matn section details',
      difficultWordsAndTheirMeanings: 'Difficult words and their meanings',
      addAnotherSection: 'Add another section',
      addAnotherSound: 'Add another sound',
      addAnotherWord: 'Add another word',
      sound: 'Sound',
      sheikName: 'Sheik name',
      uploadSound: 'Upload sound',
      save: 'Save',
      cancel: 'Cancel',
      word: 'Word',
      itsMeaning: 'Its meaning',
      addDifficultWordsAndTheirMeanings: 'Add difficult words and their meanings',
      addSounds: 'Add sounds',
    },
    addNewSound: {
      name: 'Name',
      description: 'Description',
    },
    editDiffWord: {
      header: 'Edit translation',
    },
    editSound: {
      header: 'Edit sound',
    },
    confirmMessage: 'Are you sure you want to {{editOrAdd}}?',
    confirmDeleteMessage: 'Are you sure you want to delete?',
    addNewAdmin: {
      userName: 'User name',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm password',
      role: 'Role',
    },
    certificates: {
      addNewCertificate: 'Add Certificate',
      addVariables: 'Add Variables',
    },
    addNewExam: {
      certificate: 'Certificate',
      description: 'Description',
      duration: 'Duration',
      gradeA: 'Grade A',
      gradeB: 'Grade B',
      gradeC: 'Grade C',
      gradeD: 'Grade D',
      gradeF: 'Grade F',
      minimumScore: 'Minimum score',
      limit: 'Limit',
      maxAttempts: 'Max attempts',
      category: 'Category',
      level: 'Level',
      matn: 'Matn',
      status: 'Status',
      title: 'Title',
      moreDetails: 'More details',
    },
    addNewQuestion: {
      correctAnswer: 'Correct answer',
      explanation: 'Explanation',
      hint: 'Hint',
      isQuiz: 'Is quiz',
      matnSection: 'Matn section',
      points: 'Points',
      questionText: 'Question text',
      choice: 'Choice',
      addAnotherQuestion: 'Add another question',
      addQuestionsFromTheBank: 'Add questions from the bank',
      questionsBankNote:
        'Note: This list is showing you questions that belongs to the same exam matn and not already assigned',
      examName: 'Exam name',
      choices: 'Choices',
      chooseType: 'Choose type',
    },
  },
  login: {
    mtoun: 'Mtoun',
    idealChoice: 'The ideal choice for memorizing mtoun',
    form: {
      button: 'Login',
      email: {
        label: 'Email',
        placeholder: 'Enter your email address',
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your password',
      },
      welcomeMessage: 'Welcome to Mtoun app',
    },
  },
  // Hearing councils
  hearingCouncils: {
    viewPage: {
      header: 'Hearing councils',
      search: 'Search',
      newHearingCouncil: 'New hearing council',
    },
    joinPage: {
      startSharing: 'Start sharing',
      endCall: 'End call',
      leaveMeeting: 'Leave meeting',
      connectedUsers: 'Connected users',
      confirmEnd: 'Are you sure you want to end the call for all users?',
    },
    filters: {
      expiryDate: 'Expiry Date',
      startDate: 'Start Date',
      status: 'Status',
    },
    analytics: {
      completedAtt: 'Completed attendance',
      uncompletedAtt: 'Uncompleted attendance',
    },
    card: {
      edit: 'Edit',
      startNow: 'Start now',
      startsAt: 'Starts at',
      startedAt: 'Started at',
    },
    status: {
      Expired: 'Expired',
      Live: 'Live',
      Pending: 'Pending',
    },
  },
  badges: {
    types: {
      hearingCouncils: 'Hearing councils',
      matn: 'Matn',
      category: 'Category',
    },
    uploadImage: 'Upload badge image',
    tabs: {
      general: 'General',
      custom: 'Custom',
    },
    generalTypes: {
      hearingCouncilParticipation: 'Hearing council participation',
      mutoonRecordContribution: 'Mutoon record contribution',
      sessionParticipation: 'Session participation',
      streakDays: 'Streak days',
      userSharesCount: 'User shares count',
    },
  },
  // Sessions
  sessions: {
    viewPage: {
      header: 'Sessions',
      search: 'Search',
      newSession: 'New session',
    },
    card: {
      edit: 'Edit',
      startNow: 'Start now',
      startsAt: 'Starts at',
      startedSince: 'Started since',
      join: 'Join',
      startsIn: 'Starts in',
      endedSince: 'Ended since',
      endsIn: 'Ends in',
      canStartIn: 'Can start in',
    },
    status: {
      Expired: 'Expired',
      Live: 'Live',
      Pending: 'Pending',
    },
  },
  userDetails: {
    tabs: {
      aboutUser: 'About user',
      sessions: 'Sessions',
      hearingCouncils: 'Hearing councils',
    },
    aboutUser: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      registerationInfo: 'Registration Information',
    },
    sessions: {
      title: 'Sessions',
      noSessions: {
        title: 'No sessions yet',
        subtitle: 'The user has not followed or created any sessions yet',
      },
    },
    hearingCouncils: {
      title: 'Hearing councils',
      noHearingCouncils: {
        title: 'No hearing councils yet',
        subtitle: 'The user has not followed or created any councils yet',
      },
    },
    dontaions: 'Dontions',
  },
  statistics: {
    hearingCouncils: {
      createdCouncils: 'Created councils',
      currentCouncils: 'Current councils',
      expiredCouncils: 'Expired councils',
      upcomingCouncils: 'Upcoming councils',
      title: "Today's councils",
      noCouncilsToday: 'No councils today',
    },
    sessions: {
      createdSessions: 'Created sessions',
      currentSessions: 'Current sessions',
      expiredSessions: 'Expired sessions',
      upcomingSessions: 'Upcoming sessions',
      title: "Today's sessions",
      noSessionsToday: 'No sessions today',
    },
    users: {
      table: {
        avatar: 'Avatar',
        email: 'Email',
        joined: 'Joined',
        name: 'Name',
      },
      lastJoiningUsers: 'Last joining users',
    },
    certificates: {
      givenCertificatesByDate: 'Given certificates analysis by date',
    },
    faqs: {
      title: "Today's FAQS",
    },
    contactUs: {
      title: 'Contact Us',
    },
  },
  notFound: {
    title: 'Not Found',
    subtitle:
      'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
    goToHome: 'Go to Home',
  },
  teacherRequestDetails: {
    approve: {
      categories: 'Categories',
      areYouSure: 'Are you sure you want to approve this user as a teacher?',
    },
    changeRequestStatus: {
      approve: 'Approve',
      reject: 'Reject',
      pending: 'Pending',
      title: 'Change Request Status',
    },
    reject: {
      adminMsg: 'Admin Message',
      areYouSure: 'Are you sure you want to reject this account upgrading request?',
    },
    requestInfo: {
      title: 'Request Information',
    },
    files: {
      title: 'Files',
    },
    adminMessage: 'Admin Message',
  },
  schoolRequestDetails: {
    approve: {
      areYouSure: 'Are you sure you want to approve this school account?',
    },
    changeRequestStatus: {
      approve: 'Approve',
      reject: 'Reject',
      pending: 'Pending',
      title: 'Change Request Status',
    },
    reject: {
      areYouSure: 'Are you sure you want to reject this school account request?',
    },
    requestInfo: {
      title: 'Request Information',
    },
    files: {
      title: 'Files',
    },
  },
};
export default englishJson;
