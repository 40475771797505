import { SxProps } from '@mui/material';
import { paths } from 'src/routes/paths';
import useStudents from './use-school-students';
import AppTable from 'src/components/table/app-table';
import { SchoolStudentsQuery } from 'src/graphql';
import { SCHOOL_STUDENTS_TABLE_HEAD } from 'src/constants/tables-heads';
import { TableProps } from 'src/components/table';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import { Helmet } from 'react-helmet-async';
import { ConfirmDialog } from 'src/components/custom-dialog';
import AppButton from 'src/components/common/AppButton';
import AppLoadingButton from 'src/components/common/AppLoadingButton';

type StudentsViewPropsType = {
  // All these props are used when the component is used in another module
  handleSelectRow?: (row: SchoolStudentsQuery['students'][0], table: TableProps) => void;
  handleSelectAllRows?: (
    checked: boolean,
    table: TableProps,
    rows: SchoolStudentsQuery['students'][0][]
  ) => void;
  noContainer?: boolean;
  selectedRowsActions?: {
    icon: string;
    color?: string;
    tooltip?: string;
    handler: () => void;
    isLoading?: boolean;
  }[];
  table?: TableProps;
  sx?: SxProps;
  userId: string;
  certificateHearingCouncilId: string;
};

const SchoolStudents = ({
  handleSelectRow,
  handleSelectAllRows,
  noContainer,
  selectedRowsActions,
  table: newTable, // If the component is used in another module
  sx,
  userId,
  certificateHearingCouncilId,
}: StudentsViewPropsType) => {
  const {
    isLoading,
    t,
    table,
    columns,
    students,
    methods,
    query,
    setQuery,
    handleSearch,
    confirm,
    handleExportCert,
    isCreatingCert,
  } = useStudents({ newTable: newTable!, userId, certificateHearingCouncilId });

  const viewComponent = (
    <>
      <AppTable<SchoolStudentsQuery['students'][0]>
        data={students?.students!}
        sx={sx!}
        columns={columns}
        dataCount={students?.students_aggregate.aggregate?.count!}
        headLabels={SCHOOL_STUDENTS_TABLE_HEAD(t)}
        table={newTable || table}
        isLoading={isLoading}
        handleSearch={handleSearch}
        // Select action props
        select={{
          handleSelectRow: handleSelectRow!,
          handleSelectAllRows: handleSelectAllRows!,
          numSelected: table.selected.length,
          rowCount: table.rowsPerPage,
          selectedRowsActions: selectedRowsActions,
        }}
        // end
        setQuery={setQuery}
        query={query}
        methods={methods}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.add').toLowerCase(),
        })}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isCreatingCert}
              onClick={handleExportCert}
            />
          </>
        }
      />
    </>
  );

  // TODO: complete columns and apply filtering
  return noContainer ? (
    viewComponent
  ) : (
    <AppLoadingAndErrorWrapper isLoading={false}>
      <Helmet>
        <title>{t('sidebar.userAuthorization.school')}</title>
      </Helmet>
      {viewComponent}
    </AppLoadingAndErrorWrapper>
  );
};

export default SchoolStudents;
