export const DAYS = (t: any) => [
  {
    label: t('days.sun'),
    value: 'Sun',
  },
  {
    label: t('days.mon'),
    value: 'Mon',
  },
  {
    label: t('days.tue'),
    value: 'Tue',
  },
  {
    label: t('days.wed'),
    value: 'Wed',
  },
  {
    label: t('days.thu'),
    value: 'Thu',
  },
  {
    label: t('days.fri'),
    value: 'Fri',
  },
  {
    label: t('days.sat'),
    value: 'Sat',
  },
];
