import { Tabs, Tab, Card } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLocales } from 'src/locales';
import { ContentLangType } from 'src/locales/types';

const TranslationTabs = ({
  errors,
  currentTabValue,
  handleChangeTab,
  requiredLangs,
}: {
  errors: any[];
  currentTabValue: string;
  handleChangeTab: (event: React.SyntheticEvent<Element, Event>, newValue: string) => void;
  requiredLangs?: ContentLangType;
}) => {
  const { allLangs, t } = useLocales();
  console.log(errors);

  return (
    <Card>
      <Tabs
        value={currentTabValue}
        onChange={handleChangeTab}
        sx={{
          backgroundColor: 'background.neutral',
          px: 2,
          padding: 0.75,
          borderRadius: 2,
          '.MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {[...(requiredLangs || allLangs)]!?.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={t(tab.label as 'translationTypes.english')}
            sx={{
              color: errors.includes(tab.value) ? 'red !important' : 'text.primary',
              backgroundColor:
                tab.value === currentTabValue ? 'background.default' : 'background.neutral',
              borderRadius: 2,
              fontWeight: 500,
              px: 3,
              py: 1,
            }}
          />
        ))}
      </Tabs>
    </Card>
  );
};

export default TranslationTabs;
