import { Helmet } from 'react-helmet-async';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import AppTable from 'src/components/table/app-table';
import { FINISHED_COUCNILS_ANALYSIS_TABLE_HEAD } from 'src/constants/tables-heads';
import { HearingCouncilAnalysisQuery } from 'src/graphql';
import useFinishedHearingCouncilsUsers from './use-finished-hearing-councils-users';
import { MenuItem, Select, Stack } from '@mui/material';
import { Block } from 'src/components/Block';

const FinishedHearingCouncilsUsers = ({
  certificateHearingCouncilId,
}: {
  certificateHearingCouncilId: string;
}) => {
  const {
    isLoading,
    t,
    table,
    columns,
    methods,
    query,
    setQuery,
    handleSearch,
    confirm,
    isCreatingCert,
    data,
    handleExportSelectedCert,
    confirmExportSelected,
    handleExportUserCertificate,
    selectProps,
  } = useFinishedHearingCouncilsUsers({ certificateHearingCouncilId });

  const viewComponent = (
    <>
      <AppTable<HearingCouncilAnalysisQuery['user_hearing_councils'][0]>
        data={data?.user_hearing_councils!}
        columns={columns}
        dataCount={data?.user_hearing_councils_aggregate.aggregate?.count!}
        headLabels={FINISHED_COUCNILS_ANALYSIS_TABLE_HEAD(t)}
        table={table}
        isLoading={isLoading}
        handleSearch={handleSearch}
        // Select action props
        select={selectProps}
        // end
        setQuery={setQuery}
        query={query}
        methods={methods}
      />
      <ConfirmDialog
        open={confirm.value || confirmExportSelected.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            confirmExportSelected.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.add').toLowerCase(),
        })}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                confirmExportSelected.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isCreatingCert}
              onClick={() => {
                confirmExportSelected.value
                  ? handleExportSelectedCert()
                  : handleExportUserCertificate();
              }}
            />
          </>
        }
      />
    </>
  );

  return (
    <AppLoadingAndErrorWrapper isLoading={false}>
      <Helmet>
        <title>{t('sidebar.userAuthorization.school')}</title>
      </Helmet>
      {viewComponent}
    </AppLoadingAndErrorWrapper>
  );
};

export default FinishedHearingCouncilsUsers;
