import { Container, MenuItem, Select } from '@mui/material';
import { useParams } from 'react-router';
import { useHearingCouncilAnalysisQuery, useHearingCouncilCertificatesQuery } from 'src/graphql';
import HearingCouncilsCharts from '../charts/hearing-councils-charts';
import FinishedHearingCouncilsUsers from '../table/finished-hearing-councils-users/finished-hearing-councils-users';
import TableTabs from 'src/components/table/table-tabs';
import { useLocales } from 'src/locales';
import { useState } from 'react';
import FinishedHearingCouncilsSchoolsUsers from '../table/finished-hearing-councils-schools-users/finished-hearing-councils-schools-users';
import { Block } from 'src/components/Block';

const SCHOOLS = 'schools';
const USERS = 'users';

const HearingCouncilsAnalysis = () => {
  // #region States and hooks
  const { hearingCouncilId } = useParams();
  const [tabValue, setTabValue] = useState<typeof SCHOOLS | typeof USERS>(USERS);
  const { t } = useLocales();
  const { data, isLoading } = useHearingCouncilAnalysisQuery({
    hearingCouncilId: hearingCouncilId!,
  });

  const [certificateHearingCouncilId, setCertificateHearingCouncilId] = useState<
    string | undefined
  >();
  // #endregion

  // #region Services
  // Get hearing council certificates
  const { data: hearingCouncilCertificates, isLoading: isLoadingCertificates } =
    useHearingCouncilCertificatesQuery({ hearingCouncilId: hearingCouncilId! });
  // #endregion

  return (
    <Container>
      <HearingCouncilsCharts data={data!} isLoading={isLoading} />
      <Block label={t('dialogs.addNewExam.certificate')} sx={{ py: 1 }}>
        <Select
          size="small"
          value={certificateHearingCouncilId}
          onChange={(event) => setCertificateHearingCouncilId(event.target.value)}
        >
          {hearingCouncilCertificates?.hearing_council_certificates.map((cert) => (
            <MenuItem value={cert.certificateId}>{cert.certificate.title}</MenuItem>
          ))}
        </Select>
      </Block>

      <TableTabs
        tabs={{
          currentTabValue: USERS,
          tabs: [
            { label: t('tables.finishedHearingCouncilsAnalysis.tabs.users'), value: USERS },
            { label: t('tables.finishedHearingCouncilsAnalysis.tabs.schools'), value: SCHOOLS },
          ],
        }}
        handleChangeTab={(_, value) => setTabValue(value! as typeof SCHOOLS)}
      />
      {tabValue === 'users' && (
        <FinishedHearingCouncilsUsers certificateHearingCouncilId={certificateHearingCouncilId!} />
      )}
      {tabValue === 'schools' && (
        <FinishedHearingCouncilsSchoolsUsers
          certificateHearingCouncilId={certificateHearingCouncilId!}
        />
      )}
    </Container>
  );
};

export default HearingCouncilsAnalysis;
