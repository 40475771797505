import {
  Card,
  CardHeader,
  Container,
  Divider,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import { FAQS_TABLE_HEAD } from 'src/constants/tables-heads';
import { Order_By } from 'src/graphql';
import FAQRow from './faq-row';
import useFaqs from './use-faqs';

export type FAQsFormType = {
  sortBy: string | undefined;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
};

type FAQsPropsType = {
  title?: string;
};

const FAQs = ({ title }: FAQsPropsType) => {
  const {
    data,
    handleDeleteFAQ,
    handleSearch,
    isDeleting,
    isLoading,
    methods,
    query,
    setQuery,
    t,
    table,
  } = useFaqs();

  const viewComponent = (
    <AppLoadingAndErrorWrapper isLoading={isDeleting} errorMessage={null}>
      {!title && (
        <Helmet>
          <title>{t('sidebar.faqs')}</title>
        </Helmet>
      )}
      <Card>
        {title && <CardHeader title={title} />}
        {title && <Divider sx={{ mt: 3 }} />}
        <FormProvider methods={methods}>
          <AppTableToolbar
            handleSearch={handleSearch}
            search={query.search!}
            tableName=""
            buttons={[
              {
                path: '/dashboard/help-center/faqs/new',
                permissionName: AppPermissions.CONTENT_CREATE,
              },
            ]}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom
                  setQuery={setQuery}
                  headLabel={FAQS_TABLE_HEAD(t)}
                  methods={methods}
                />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.faqs?.map((row) => (
                        <FAQRow key={row.id} row={row} deleteHandler={handleDeleteFAQ} />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.faqs_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
      </Card>
    </AppLoadingAndErrorWrapper>
  );

  return title ? viewComponent : <Container>{viewComponent}</Container>;
};

export default FAQs;
