import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';
import RHFDatePicker from 'src/components/hook-form/rhf-date-picker';
import { RHFMultiSelect, RHFSelect } from 'src/components/hook-form/rhf-select';
import { DAYS } from 'src/constants/days';
import useAddNewHearingCouncil from './use-add-new-hearing-council';
import { AR_CONTENT_LANG, EN_CONTENT_LANG } from 'src/locales';

const AddNewHearingCouncil = () => {
  const {
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    handleChangeTab,
    langsWithErrors,
    t,
    currentLang,
    hasGotMtns,
    mutoonOptions,
    isEditing,
    isAdding,
    confirm,
    mutate,
    isUpdating,
    admins,
    certificates,
    isLoadingCertificates,
    generalMutoonOptions,
    hasGotGeneralMatns,
    requiredLangs,
  } = useAddNewHearingCouncil();

  const durationValue = moment.duration(
    moment(methods.watch().expiryDate).diff(moment(methods.watch().startDate), 'minutes')
  );

  console.log(methods.watch());

  return (
    <>
      <Container>
        <Helmet>
          <title>{t('titles.addHearingCouncil')}</title>
        </Helmet>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {/* #region Council details */}
            <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewHearingCouncil.accordions.councilDetails')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <TranslationTabs
                        currentTabValue={currentTabValue}
                        handleChangeTab={handleChangeTab}
                        errors={langsWithErrors}
                        requiredLangs={requiredLangs}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Block label={t('dialogs.addNewHearingCouncil.sessionName')}>
                        <RHFTextField name={`sessionName.${currentTabValue}`} />
                      </Block>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Block label={t('dialogs.addNewHearingCouncil.sheikhName')}>
                        {/* <RHFTextField name={`sheikhName.${currentTabValue}`} /> */}
                        <RHFSelect name="sheikhName">
                          {admins?.admins.map((admin) => (
                            <MenuItem value={admin.id}>{admin.username}</MenuItem>
                          ))}
                        </RHFSelect>
                      </Block>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Block label={t('dialogs.addNewHearingCouncil.description')}>
                        <RHFTextField name={`description.${currentTabValue}`} />
                      </Block>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* #endregion Council details */}

            {/* #region Jitsi preferences */}
            {/* TODO: reenable jitsi preferences */}
            {/* <Grid item md={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewSession.accordions.jitsiPreferences')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableConversation')}
                        >
                          <RHFSwitch name="enable_conversation" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableRaiseHand')}
                        >
                          <RHFSwitch name="enable_raise_hand" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableVideo')}
                        >
                          <RHFSwitch name="enable_video" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableRecording')}
                        >
                          <RHFSwitch name="enable_recording" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card sx={{ padding: 2 }}>
                        <Block
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          label={t('dialogs.addNewSession.enableUploadFiles')}
                        >
                          <RHFSwitch name="enable_upload_files" label="" />
                        </Block>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid> */}
            {/* #endregion Jitsi preferences */}

            {/* #region Date and time */}
            <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewSession.accordions.dateAndTime')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewHearingCouncil.startDate')}>
                        <RHFDatePicker name="startDate" dateAndTime minDate={new Date()} />
                      </Block>
                    </Grid>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewHearingCouncil.expiryDate')}>
                        <RHFDatePicker
                          name="expiryDate"
                          dateAndTime
                          minDate={moment(methods.watch().startDate).toDate()}
                        />
                      </Block>
                    </Grid>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewSession.duration')}>
                        <RHFTextField
                          name="duration"
                          type="number"
                          value={durationValue}
                          disabled
                        />
                      </Block>
                    </Grid>
                    <Grid item xs={6}>
                      <Block label={t('dialogs.addNewSession.days')}>
                        <RHFMultiSelect options={DAYS(t)} name="days" checkbox chip />
                      </Block>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* #endregion Date and time */}

            {/* #region Matn details */}
            <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewSession.accordions.matnDetails')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Block label={t(`dialogs.addNewHearingCouncil.matn`)}>
                        <RHFAutocomplete
                          name="mutoon"
                          multiple
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          disabled={!hasGotMtns}
                          options={
                            mutoonOptions?.mutoon_categories.map((mtn) => ({
                              label: mtn.mutoon.name[currentLang.value]!,
                              id: mtn.mutoonId!,
                            })) || []
                          }
                          onChange={(e, value) => {
                            methods.setValue(
                              'mutoonIds',
                              (value as { id: string; label: string }[]).map((v) => v.id)
                            );
                            methods.clearErrors('mutoonIds');
                          }}
                        />
                      </Block>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Block label={t(`dialogs.addNewHearingCouncil.generalMatn`)}>
                        <RHFAutocomplete
                          name="generalMutoon"
                          multiple
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          disabled={!hasGotGeneralMatns}
                          options={
                            generalMutoonOptions?.mutoon_categories.map((mtn) => ({
                              label: mtn.mutoon.name[currentLang.value]!,
                              id: mtn.mutoonId!,
                            })) || []
                          }
                          onChange={(e, value) => {
                            methods.setValue(
                              'generalMutoonIds',
                              (value as { id: string; label: string }[]).map((v) => v.id)
                            );
                            methods.clearErrors('mutoonIds');
                          }}
                        />
                      </Block>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="error" variant="caption">
                        {methods.formState.errors.mutoonIds?.message}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <Block label={t('dialogs.addNewSession.prerequisites')}>
                        <RHFMultiSelect
                          name="prerequisiteIds"
                          options={prerequisitesOptions || []}
                          disabled={!hasGotMtns}
                          chip
                          checkbox
                        />
                      </Block>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* #endregion matn details */}

            {/* #region Certificate details */}
            <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewSession.accordions.certificateDetails')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid container item xs={12} md={6} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Block label={t('dialogs.addNewHearingCouncil.certificate')}>
                          <RHFMultiSelect
                            options={
                              certificates?.certificates.map((crt) => ({
                                label: crt.title!,
                                value: crt.certificateId!,
                              })) || []
                            }
                            name="certificateIds"
                            checkbox
                            chip
                          />
                        </Block>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <Block label={t('dialogs.addNewSession.prerequisites')}>
                        <RHFMultiSelect
                          name="prerequisiteIds"
                          options={prerequisitesOptions || []}
                          disabled={!hasGotMtns}
                          chip
                          checkbox
                        />
                      </Block>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* #endregion matn details */}

            <Grid item md={6} xs={12}>
              {/* <Block label={t('dialogs.addNewHearingCouncil.price')}>
                <RHFTextField name="price" type="number" />
              </Block> */}
            </Grid>
            <Grid item xs={12}>
              <Block label={t('dialogs.addNewHearingCouncil.appointmentLimit')}>
                <RHFTextField name="appointmentLimit" type="number" />
              </Block>
            </Grid>
            <Grid item xs={12} md={6}>
              <AppLoadingButton
                label={isEditing ? t('buttons.edit') : t('buttons.add')}
                isLoading={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewHearingCouncil;
