// @mui
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ConfirmDialogProps } from './types';
import { Icon } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Iconify from '../iconify/iconify';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  close,
  icon,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      {...other}
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <DialogTitle sx={{ pb: 2 }} display="flex" justifyContent="flex-end">
        <Box display="flex">
          {close && (
            <IconButton onClick={close}>
              <Iconify icon="material-symbols:cancel-outline" />
            </IconButton>
          )}
          {title && title}
        </Box>
      </DialogTitle>

      {content && (
        <DialogContent>
          <Stack p={5} direction="column" spacing={3}>
            {icon}
            <Typography fontSize={28} fontWeight={500} textAlign="center">
              {content}
            </Typography>
          </Stack>
        </DialogContent>
      )}

      <DialogActions style={{ justifyContent: 'center' }}>{action}</DialogActions>
    </Dialog>
  );
}
